import React from "react"
import { Layout } from "components/layout/Layout"
import { Meta } from "components/meta/Meta"
import { Hero } from "components/hero"
import rocketImg from "../assets/images/404/rocket.svg"

const heroProps = {
  subtitle: "404",
  title: (
    <>
      There is <mark>no intelligent life</mark> on this page.
    </>
  ),
}

const NotFound = (props) => (
  <Layout location={props.location}>
    <Meta title="404" />
    <Hero
      fullscreenWithFooter
      topImage={<img src={rocketImg} alt="Rocket" />}
      {...heroProps}
    />
  </Layout>
)

export default NotFound
